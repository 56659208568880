.formidable {
    position: relative;
    margin: 0;
    width: 100%;
  
    > div {
        width: 100%;
    }

    .col-12 {
        padding: 16px;
    }
  
    [data-formidable-type="captcha"] {
        display: none !important;
    }
  
    [data-formidable-type="content"] {
        h1,
        h2,
        h3 {
            margin-bottom: 60px;
        }

        label {
            display: none !important;
        }
    }

    textarea {
        min-height: 100px !important;
    }

    .form-group {
        .small.text-danger {
            color: $color-red;
        }

        .invalid-feedback {
            font-size: 12px;
            margin-top: -16px;
            margin-bottom: 16px;
            color: $color-red;
        }
    }

    .form-control {
        &.is-invalid {
            border-color: $color-red;
        }
    }

    button[type="submit"] {
        @extend .btn--filled, .btn--red;

        margin-left: auto;
        margin-top: -16px !important;
        margin-right: 0 !important;
        display: table !important;

        [class*="fa"] {
            display: none;
        }
    
        &:disabled {
            [class*="fa"] {
                display: inline-flex;
            }
        }
    }
}


  

    // .input {
    //   &.has-error {
    //     input[type="tel"],
    //     input[type="text"],
    //     input[type="email"],
    //     textarea,
    //     select {
    //       border-color: $color-red;
    //     }
    //   }
    // }
  
    // .text-danger {
    //   color: $color-red;
    // }