@mixin background-image-svg($name) {
  background-image: url("../images/#{$name}.png");
  background-image: url("../images/#{$name}.svg");
}

@mixin transition() {
  -webkit-transition: all $duration;
  -moz-transition: all $duration;
  -ms-transition: all $duration;
  -o-transition: all $duration;
  transition: all $duration;
}

@mixin rotate($deg: 90) {
  $sDeg: #{$deg}deg;
  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);
  transition-property: transform;
  @include transition(); // Add this also to target div
}

@mixin responsive-vw($properties, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-width) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-width}) /
          #{strip-unit($max-width - $min-width)}
      );
    }
  }

  @media screen and (min-width: $max-width) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/* --- Grid --- */
@mixin grid-breakpoint($prefix) {
  @include grid-media(map-get($neat-breakpoint-grid, $prefix)) {
    @for $i from 1 through $grid-columns {
      .grid__col--#{$prefix}--#{$i} {
        @include grid-column($i);

        &.grid__col--no-margin {
          width: (100% / $grid-columns) * $i;
        }
      }
    }
  }
}
