.footer {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  color: $color-white;

  &__bottom {
    padding: 20px 0;
  }

  a {
    color: $color-white;
    text-decoration: none;
    line-height: 2;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $color-white;
  }

  @media only screen and (max-width: 996px) {
    padding-bottom: 80px;
    margin-bottom: 25px;
  }
}
