/* ==========================================================================
Import Bootstrap Breakpoints
========================================================================== */
// Blackbox
@import "variables";

/* ==========================================================================
Cookiebot
========================================================================== */
#CybotCookiebotDialog {
  font-family: $font-family-default !important;
  width: calc(100% - 30px);

  #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogPoweredByText,
  #CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    color: $color-red !important;

    &::after {
      color: $color-red !important;
    }
  }

  #CybotCookiebotDialogFooter {
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {

      background-color: $color-red !important;
      border-color: $color-red !important;
      opacity: 1 !important;
      width: 100% !important;
    }

    #CybotCookiebotDialogBodyButtonDecline,
    #CybotCookiebotDialogBodyLevelButtonCustomize,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      background-color: transparent !important;
      border-color: $color-red !important;
      color: $color-red !important;
      width: 100% !important;

      &.CybotCookiebotDialogHide {
        display: none !important;
      }

      .CybotCookiebotDialogArrow {
        display: none !important;
      }
    }
  }

  #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-red;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-color: $color-red;
    color: $color-red;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: $color-red;
  }
}
