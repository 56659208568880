.mobile__nav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 10;

	@media only screen and (min-width: 768px) {
		display: none;
	}

	.navbar-mask-mobile {
		bottom: 0;
	    height: 85px;
	    width: 131px;
	}

	.navbar-mask {
	    left: 0;
	    position: absolute;
	    right: 0;
	    z-index: 1;
	    margin: 0 auto;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.navbar--mobile-item {
		background-color: #e20d2b;
	    box-shadow: inset 0 3px 3px -3px rgba(0,0,0,.15);
	    height: 85px;
	    position: relative;
	    text-align: center;
	    width: calc(25% - 20px);
	    align-items: center;
	    display: flex;
	    z-index: 5;
	    justify-content: center;

	    &:nth-child(2) {
	    	margin-right: auto !important;
	    }

	    a {
	    	color: white;
	    	text-decoration: none;
	    }
	}

	.navbar--mobile-btn {
	    border-radius: 50%;
	    bottom: 55px;
	    box-shadow: 0 3px 3px 3px rgba(0,0,0,.15);
	    background-color: $color-red;
	    height: 67px;
	    margin: 0 auto;
	    left: 3px;
	    line-height: 1;
	    position: absolute;
	    right: 0;
	    width: 67px;
	    z-index: 4;

	    img {
	    	max-width: none !important;
		    display: block;
		    transform: translate(-50%, -50%);
		    margin: 0 auto;
		    top: 50%;
		    left: 50%;
		    position: absolute;
	    }
	}
}

.exclude-mobile {
	@media only screen and (max-width: 769px) {
		display: none;
	}
}

.ccm-edit-mode {
	.mobile__nav {
		display: block !important;
		position: relative;
		margin-top: 50px;
	}
}
