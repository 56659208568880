$neat-grid: (
  columns: $columns,
  gutter: $gutter,
);

$neat-breakpoint-grid: ();

@each $key, $value in $grid-breakpoints {
  $neat-breakpoint-grid: map-merge(
    $neat-breakpoint-grid,
    (
      $key: (
        columns: $columns,
        gutter: $gutter,
        media: "screen and (max-width: #{$value})",
      ),
    )
  );
}

$lg: map-get($neat-breakpoint-grid, lg);
$md: map-get($neat-breakpoint-grid, md);
$sm: map-get($neat-breakpoint-grid, sm);
$xs: map-get($neat-breakpoint-grid, xs);

$grid-columns: map-get($neat-grid, columns);

.grid {
  &__container {
    position: relative;
    max-width: $max-width;
    margin: 0 auto;

    &--fluid {
      position: relative;
      max-width: 100%;
      margin: 0 auto;
    }

    &--visual {
      @include grid-visual();
    }
  }

  &__row {
    @include grid-container;
  }

  &__col {
    @for $i from 1 through $grid-columns {
      &--#{$i} {
        position: relative;
        @include grid-column($i);
        box-sizing: border-box;

        &.grid__col--no-margin {
          width: (100% / $grid-columns) * $i;
          margin-left: 0;
        }
      }

      &--fifth {
        position: relative;
        width: calc(20% - 19px);
        float: left;
        margin-left: $gutter;
        box-sizing: border-box;

        &.grid__col--no-margin {
          width: 20%;
          margin-left: 0;
        }
      }
    }
  }

  &__shift {
    @for $i from 1 through $grid-columns {
      &--#{$i} {
        @include grid-push($i);

        &.grid__col--no-margin {
          margin-left: (100% / $grid-columns) * $i;
        }
      }
    }
  }

  &__collapse {
    @include grid-collapse();
    position: relative;
    float: left;
  }
}

@each $key, $value in $grid-breakpoints {
  @include grid-breakpoint($key);
}
