@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .section {
    .grid__row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
    }
    .grid__row > [class*="grid__col--"] {
      display: flex;
      flex-direction: column;
    }
  }
}
