.contact {
  position: relative;
  padding: 60px;

  &:focus {
    outline: 0;
  }

  @include grid-media($md) {
    padding: 40px 20px;
  }
}

.contact--block-title {
  margin-bottom: 10px;
}

.contact--block-text {
  margin-bottom: 30px;
}

.contact--item {
  @include grid-media($md) {
    margin-bottom: 20px;
  }
}

.contact--img {
  width: 100%;
  height: 354px;
  object-fit: cover;
  object-position: center;

  @include grid-media($md) {
    height: 250px;
  }
}

.contact--phone,
.contact--email {
  font-size: 14px;
  color: $color-red !important;

  @include grid-media($md) {
    display: block;
  }

  @include grid-media($sm) {
    display: inline-block;
    font-size: 12px;
  }

  i,
  svg {
    margin-right: 3px;
  }
}

.contact--phone {
  margin-right: 20px;
}
