.card-location {
  position: relative;
  margin-bottom: 15px;

  &--overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(21, 21, 21, 0.4) 0%, rgba(21, 21, 21, 0.4) 100%);

    @include transition();
  }

  &--content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: $color-white;
    text-align: center;
  }

  &:hover {
    .card-location--overlay::before {
      opacity: 0;
    }
  }
}
