.carousel {
  position: relative;
  padding: 60px;

  &:focus {
    outline: 0;
  }

  @include grid-media($md) {
    padding: 40px 20px;
  }

  .carousel--item {
    position: relative;

    @include transition();

    &:hover,
    &:focus {
      .btn {
        border-color: #c00b25;
        background-color: #c00b25;
      }
    }
  }

  .carousel--img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .carousel--inner {
    &::before {
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      background: rgba($color-black, 0.4) 0%;

      @include transition();
    }
  }

  .carousel--overlay {
    &::before {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      background: rgba($color-black, 0.4) 0%;
    }
  }

  .carousel--content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 0 20px;
    color: $color-white;
    z-index: 3;
  }

  .carousel--title {
    margin-bottom: 20px;
  }
  .carousel--block-text {
    color: $color-black;
    margin-bottom: 30px;
  }

  .carousel--block-title {
    margin-bottom: 20px;
  }

  .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: -1;

    @include grid-media($md) {
      display: none;
    }

    i,
    svg {
      font-size: 40px;
    }
  }

  .owl-prev {
    margin-left: -40px;
  }

  .owl-next {
    float: right;
    margin-right: -40px;
  }

  .owl-dots {
    display: none;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    padding-top: 15px;

    @include grid-media($md) {
      display: block;
    }
  }

  .owl-dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin: 0 3px;
    background-color: rgba($color-white, 0.3) !important;

    &.active {
      background-color: $color-white !important;
    }
  }
}

//Carousel Blocks
.carousel--blocks {
  .carousel--item {
    height: 500px;
  }

  .carousel--img {
    height: 500px;
  }

  .carousel--title {
    @extend .h2;
  }
}

// Carousel Full Width
.carousel--full-width {
  .carousel--item {
    height: 700px;

    @include grid-media($md) {
      height: 500px;
    }
  }

  .carousel--img {
    height: 700px;

    @include grid-media($md) {
      height: 500px;
    }
  }

  .carousel--title {
    @extend .h2;
  }
}
