html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

body {
  height: 100%;
  background-color: $color-body-background;
}

.page {
  position: relative;
  width: 100%;
  height: 100%;
  &__wrap {
    width: 100%;
  }
  img {
    max-width: 100%;
  }
}

#map {
  height: 600px;
}

.TA {
  position: relative;
}

.TA_restaurantWidgetGreen {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.infowindow {
  small {
    display: block;
  }
}

.menu {
  display: inline-block;

  img {
    max-width: 210px;
  }
}

.link {
  font-size: $max-font-size-nav;
  font-weight: 700;
  display: inline-block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 25px;
  cursor: pointer;
  background-color: $color-red;
  color: $color-white !important;
}

.df-lightbox-wrapper {
  background-color: rgba($color-black, 0.7) !important;
}

.df-container,
.df-floating {
  background: none !important;
}

.df-lightbox-controls {
  padding: 30px !important;
}

.df-lightbox-close {
  color: $color-white !important;
}

.df-container > .df-ui-next,
.df-container > .df-ui-prev {
  color: $color-white !important;
  opacity: 1 !important;
}

img.lazy {
  transition: all $duration;
  opacity: 0;

  &.loaded,
  &.error {
    opacity: 1;
  }

  &:not([src]) {
    visibility: hidden;
  }
}
