.slider {
  position: relative;
  padding: 60px;

  &:focus {
    outline: 0;
  }

  @include grid-media($md) {
    padding: 40px 20px;
  }

  .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: -1;

    @include grid-media($md) {
      display: none;
    }

    i,
    svg {
      font-size: 40px;
      color: $color-red;
    }
  }

  .owl-prev {
    margin-left: -40px;
  }

  .owl-next {
    float: right;
    margin-right: -40px;
  }

  .owl-dots {
    display: none;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    padding-top: 15px;

    @include grid-media($md) {
      display: block;
    }
  }

  .owl-dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin: 0 3px;
    background-color: rgba($color-white, 0.3) !important;

    &.active {
      background-color: $color-white !important;
    }
  }
}

.slider--block-title {
  margin-bottom: 20px;
}

.slider--block-text {
  margin-bottom: 20px;
}

.slider--item {
  position: relative;
}

.slider--img {
  height: 554px;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @include grid-media($sm) {
    height: 300px;
  }
}

.slider--video {
  height: 554px;
}

.slider--expand {
  position: absolute;
  right: 15px;
  bottom: 10px;

  i,
  svg {
    font-size: 22px;
    color: $color-white;
  }
}
