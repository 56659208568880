.press {
  &--card {
    display: inline-block;

    &-content {
      margin-bottom: 15px;

      a {
        text-decoration: none;
      }
    }

    &-category {
      position: absolute;
      left: 20px;
      bottom: 20px;
      padding: 10px;
      color: $color-white;
      background-color: $color-red;
      font-weight: bold !important;
      z-index: 2;
    }
  }

  &__list {
    position: fixed;
    margin-left: 0px !important;

    @include grid-media($md) {
      position: relative;
    }

    li {
      list-style-type: none;

      a {
        text-decoration: none;
        @include transition();

        &:hover {
          color: $color-red;
        }
      }
    }

    .active {
      a {
        color: $color-red;
      }
    }
  }

  &__overlay {
    top: 0;
  }

  &--image {
    margin-bottom: 13px;
    margin-right: 0px !important;

    // &:nth-child(even) {
    //   margin-right: 0px !important;
    // }

    &.height--250 {
      margin-left: 0 !important;
      width: calc(50% - 6px);

    }

    i,
    svg {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: $color-white;
      font-size: 42px;
      z-index: 2;
      @include transition();
    }

    .press__overlay {
      opacity: 0;
      @include transition();
    }

    &:hover {
      .press__overlay {
        opacity: 1;
      }

      i,
      svg {
        opacity: 1;
      }
    }
  }
}
