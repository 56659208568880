.content__block {
  @include grid-media($sm) {
    .background--image-desktop {
      display: none;
    }
  }
  
  .background--image-mobile {
    display: none;
  }
  
  
  @include grid-media($sm) {
    .background--image-mobile {
      display: block;
    }
  }
}
