@font-face {
  font-family: "Hermes";
  src: url("../fonts/hermesbold-webfont.eot");
  src: url("../fonts/hermesbold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/hermesbold-webfont.woff") format("woff"),
    url("../fonts/hermesbold-webfont.ttf") format("truetype"),
    url("../fonts/hermesbold-webfont.svg#HermesBold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
