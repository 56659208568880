.hero {
  position: relative;
  width: 100%;

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__section-border {
    border-bottom: 5px solid $color-red;
  }

  &__logo {
    display: flex !important;
    justify-content: center;
    padding: 0 40px !important;

    @include grid-media($sm) {
      padding: 40px 20px 20px !important;
    }

    img {
      position: relative;
      max-width: 370px !important;
      height: auto !important;
    }
  }

  .section__block {
    min-height: 500px;

    @include grid-media($sm) {
      min-height: 300px !important;

      .background--image {
        min-height: 300px !important;
      }
    }

    .hero__buttons {
      margin: 0 auto;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 20px;

      @include grid-media($sm) {
        padding-top: 20px;
      }

      .btn {
        margin-top: 0 !important;
      }
    }
  }

  @include grid-media($sm) {
    &__logo {
      img {
        width: 75%;
      }
    }
  }

  @include grid-media($xs) {
    .background--image-desktop {
      display: none;
    }
  }
  
  .background--image-mobile {
    display: none;
  }
  
  @include grid-media($xs) {
    .background--image-mobile {
      display: block;
    }
  }
}

.hero__mobile {
  .hero__logo {
    display: none;
  }

  .section__block {
    min-height: 0 !important;

    .background--image {
      position: relative !important;
      min-height: 0 !important;
      object-fit: contain !important;
    }
  }
}
