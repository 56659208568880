.rotate {
  @include rotate(180);
}

.float {
  &--left {
    float: left;
  }

  &--right {
    float: right;
  }
}

.text {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.tinted {
  &--light {
    background-color: rgba($color-black, 0.3);
  }
  &--dark {
    background-color: rgba($color-black, 0.7);
  }
}

.background {
  &--white {
    background-color: $color-white;
    &.background--transparent {
      background-color: rgba($color-white, 0.7);
    }
  }

  &--black {
    background-color: $color-black;
    &.background--transparent {
      background-color: rgba($color-black, 0.7);
    }
  }

  &--light-gray {
    background-color: $color-light-gray;
    &.background--transparent {
      background-color: rgba($color-light-gray, 0.7);
    }
  }

  &--red {
    background-color: $color-red;
    &.background--transparent {
      background-color: rgba($color-red, 0.7);
    }
  }

  &--blue {
    background-color: $color-blue;
    &.background--transparent {
      background-color: rgba($color-blue, 0.7);
    }
  }

  &--light-blue {
    background-color: $color-light-blue;
    &.background--transparent {
      background-color: rgba($color-light-blue, 0.7);
    }
  }

  &.background--cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: 50% 50%;
    font-family: 'object-fit: cover; object-position: center;';
    z-index: -1;
  }
}

@include grid-media($sm) {
  .background--image-mobile {
    height: 330px;
    min-height: 330px !important;
  }
}

.color {
  &--white {
    color: $color-white;
  }

  &--black {
    color: $color-black;
  }

  &--red {
    color: $color-red;
  }

  &--light-blue {
    color: $color-light-blue;
  }

  &--light-gray {
    color: $color-light-gray;
  }
}

.table {
  display: table;
  height: 100%;

  &__cell {
    display: table-cell;

    &--vertical-middle {
      vertical-align: middle;
    }

    &--vertical-bottom {
      vertical-align: bottom;
    }
  }
}

.table-cell {
  display: table-cell;
}

.d-none {
  display: none;
}

.image {
  &--cover {
    object-fit: cover;
    object-position: center;
    -o-object-fit: cover;
    -o-object-position: 50% 50%;
    font-family: 'object-fit: cover; object-position: center;';
  }

  &--contain {
    object-fit: contain;
    object-position: center;
    font-family: 'object-fit: contain; object-position: center;';
  }
}

.center {
  &--top {
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(0%);
  }

  &--left {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateY(0%) translateX(-50%);
  }

  &--middle {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.height {
  &--stretch {
    height: 100%;
  }

  &--small {
    height: 300px;
  }

  &--xsmall {
    height: 200px;
  }

  &--medium {
    height: 450px;
  }

  &--400 {
    height: 400px;
  }

  &--250 {
    height: 250px;
  }
}

.min-height--large {
  min-height: 600px;
}

@include grid-media($lg) {
  .lg--height {
    &-medium {
      height: 500px;
    }
  }

  .lg-table__cell--vertical-middle {
    vertical-align: middle;
  }
}

@include grid-media($md) {
  .md--padding-horizontal {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md--padding-left {
    padding-left: 20px;
  }

  .md--height {
    &-small {
      height: 300px;
    }
  }

  .md-table__cell--vertical-middle {
    vertical-align: middle;
  }
}

@include grid-media($sm) {
  .sm-table__cell--vertical-middle {
    vertical-align: middle;
  }

  .sm--order {
    display: flex !important;
    flex-direction: column;

    &-top {
      order: 1;
    }

    &-bottom {
      order: 2;
    }
  }

  .sm--padding-left {
    padding-left: 20px;
  }

  .sm--padding-horizontal {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm--height {
    &-auto {
      height: auto !important;
    }

    &-small {
      height: 350px;
    }
  }

  .sm--min-height {
    min-height: 250px;
  }
}

.width {
  &--stretch {
    width: 100%;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.disable-scrolling {
  overflow: hidden;
}

.content--spacing {
  padding: 50px;
}

.padding-top {
  padding-top: 30px;
}

.padding-bottom {
  padding-bottom: 30px;
}

.padding-bottom--small {
  padding-bottom: 10px;
}

.margin-top {
  margin-top: 20px;
}

.margin-left {
  margin-left: 20px;
}

.no-margin-right {
  margin-right: 0px !important;
}

.grid__col--omega {
  margin-right: 0 !important;
  width: 33.33333% !important;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid--no-container {
  width: 100vw !important;
  transform: translateX(-50%);
  left: 50%;

  .center--left {
    left: 0;
    transform: translateY(0%) translateX(0%);
  }
}

._df_custom {
  display: none !important;
}

.menu {
  padding: 0 40px;

  @include grid-media($sm) {
    padding: 20px 0;
  }
}

.figure__caption {
  margin-top: 10px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.figure__image {
  @include grid-media($sm) {
    img {
      width: 100%;
      max-width: 420px !important;
    }
  }
}

.figure__bottom {
  margin-top: 60px !important;

  @include grid-media($lg) {
    margin-top: 10px !important;
  }
}

.figure--bg {
  height: 320px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  @include transition();

  @include grid-media($sm) {
    height: 400px !important;
  }

  .table,
  .vertical__align {
    position: relative;
    height: 100%;
    z-index: 2;
  }

  &:hover,
  &:focus {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);

    .figure--overlay {
      opacity: 0.3;
    }
  }
}

.figure--overlay {
  @include transition();

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    background: rgba($color-black, 0.3) 0%;

    @include transition();
  }
}

.figure--large {
  font-size: 56px !important;

  @include grid-media($md) {
    font-size: 37px !important;
  }

  @include grid-media($sm) {
    font-size: 42px !important;
  }
}

#gifty-toggle-container {
  overflow: hidden;

  button {
    overflow: hidden;
  }
}

#gifty-toggle-container,
#gifty-toast-container {
  @media only screen and (max-width: 996px) {
    display: none !important;
  }
}

#toggle {
  overflow: hidden !important;
}

@media only screen and (max-width: 768px) {
  .ft-widget-side,
  .ft-widget-b2 {
    bottom: 80px !important;
  }

  .ft-w-open {
    .ft-widget-side.ft-w-page-search,
    .ft-widget-side.ft-w-page-ticket,
    .ft-widget-side.ft-w-page-error,
    .ft-widget-b2.ft-w-page-search,
    .ft-widget-b2.ft-w-page-ticket,
    .ft-widget-b2.ft-w-page-error {
      height: calc(100% - 80px) !important;
    }
  }

  html
    body
    #gp-m7suyiwd-root.cleanslate.gp-m7suyiwd-root
    .gp-m7suyiwd-widget-container.is-bottom-right {
    bottom: 100px !important;
    right: 10px !important;
  }
}

@media only screen and (max-width: 412px) {
  .ft-widget-side,
  .ft-widget-b2 {
    right: -10px !important;
    bottom: 100px !important;
  }

  .ft-w-open {
    .ft-widget-side.ft-w-page-search,
    .ft-widget-side.ft-w-page-ticket,
    .ft-widget-side.ft-w-page-error,
    .ft-widget-b2.ft-w-page-search,
    .ft-widget-b2.ft-w-page-ticket,
    .ft-widget-b2.ft-w-page-error {
      height: calc(100% - 100px) !important;
    }
  }

  html
    body
    #gp-m7suyiwd-root.cleanslate.gp-m7suyiwd-root
    .gp-m7suyiwd-widget-container.is-bottom-right {
    bottom: 120px !important;
  }
}
