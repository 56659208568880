.page {
  line-height: $line-height-default;
  font-family: $font-family-default;
  color: $color-text-default;

  @for $i from 1 through 3 {
    h#{$i} {
      font-family: $font-family-heading;
      text-transform: uppercase;
      line-height: $line-height-heading;
      font-weight: $font-weight-bold;
    }
  }

  h1,
  .h1 {
    font-size: $max-font-size-h1;
    @include grid-media($sm) {
      font-size: $min-font-size-h1;
    }
  }

  h2,
  .h2 {
    font-size: $max-font-size-h2;
    @include grid-media($sm) {
      font-size: $min-font-size-h2;
    }
  }

  h3,
  .h3 {
    font-size: $max-font-size-h3;
    @include grid-media($sm) {
      font-size: $min-font-size-h3;
    }
  }

  h4,
  .h4 {
    font-size: $max-font-size-h4;
    @include grid-media($sm) {
      font-size: $min-font-size-h4;
    }
    font-weight: $font-weight-bold;
    line-height: $line-height-default;
  }

  h5,
  .h5 {
    font-size: $max-font-size-h5;
    @include grid-media($sm) {
      font-size: $min-font-size-h5;
    }
    font-weight: $font-weight-bold;
    line-height: $line-height-default;
  }

  h6,
  .h6 {
    font-size: $max-font-size-h6;
    @include grid-media($sm) {
      font-size: $min-font-size-h6;
    }
    font-weight: $font-weight-bold;
    line-height: $line-height-default;
  }

  p {
    font-size: $max-font-size-p;
    @include grid-media($sm) {
      font-size: $min-font-size-p;
    }
    font-family: $font-family-default;
    font-weight: $font-weight-normal;
    line-height: $line-height-default;
  }

  a {
    color: inherit;
    @include transition();

    &:hover {
      text-decoration: none;
    }
  }

  big {
    font-size: $max-font-size-large;
    @include grid-media($sm) {
      font-size: $min-font-size-large;
    }
  }

  small {
    font-size: $max-font-size-small;
    font-weight: $font-weight-light;
    @include grid-media($sm) {
      font-size: $min-font-size-small;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  ul {
    margin-left: 20px;
    list-style: disc;

    li {
      line-height: $line-height-default;
      font-size: $max-font-size-p;

      @include grid-media($sm) {
        font-size: $min-font-size-p;
      }
    }
  }

  ol {
    margin-left: 20px;
    list-style: decimal;

    li {
      line-height: $line-height-default;
      font-size: $max-font-size-p;

      @include grid-media($sm) {
        font-size: $min-font-size-p;
      }
    }
  }
}
