/* ==========================================================================
Menu page list
========================================================================== */
.menu-container {
  padding: 40px;

  .grid__container {
    border: 4px dotted $color-red;
    border-radius: 40px;
  }

  .menu-wrapper {
    padding: 30px;
  }

  .ccm-block-page-list-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .list-title {
    font-size: 146px;
    border-top: 8px solid $color-red;
    border-bottom: 8px solid $color-red;
    padding: 15px 0;
  }

  .grid__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
  }

  .menu-category {
    margin: 0;
    padding: 0 10px;

    &:last-of-type {
      margin-left: auto !important;
    }
  }

  .menu-title {
    border-top: 2px solid $color-red;
    border-bottom: 2px solid $color-red;
    padding: 10px 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 36px;
  }

  .menu-dish {
    margin-bottom: 20px;
  }

  .menu-dish--img {
    display: inline;
    max-width: 15px;
    margin-right: 5px;
    margin-bottom: 4px;
  }

  .menu-dish--title {
    display: flex;
  }

  .menu-dish--subtitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .menu-dish--price {
    padding-left: 20px;
    text-align: right;
  }

  .menu-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      height: 100%;
      max-width: 300px;
      object-fit: contain;
    }
  }
}

@include grid-media($lg) {
  .menu-container {
    .list-title {
      font-size: 100px;
    }
  }
}

@include grid-media($sm) {
  .menu-container {
    padding: 15px;

    .menu-category {
      padding: 0;
    }

    .menu-wrapper {
      padding: 30px 15px;
    }

    .list-title {
      font-size: 52px;
    }

    .menu-title {
      font-size: 26px;
    }

    .menu-image {
      margin-top: 20px;
      margin-bottom: 30px;

      img {
        max-width: 200px;
      }
    }
  }
}
