.form {
  margin-top: 40px;

  h2 {
    margin-bottom: 20px;
  }

  .form-group {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  legend {
    display: none;
  }

  label {
    font-family: "Hermes", sans-serif;
    text-transform: uppercase;
    font-size: $max-font-size-p;
    font-weight: 700;
    padding-bottom: 0;
    display: inline-block;
    color: $color-black;
    cursor: text;
  }

  input[type="tel"],
  input[type="text"],
  input[type="email"],
  textarea,
  select {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: $max-font-size-p;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    border: 1px solid $color-black;
    border-radius: 0;
    -webkit-appearance: none;
    outline: none;
    resize: none;
    box-sizing: border-box;

    &:focus {
      border-color: $color-red;
    }
  }

  input[type="file"] {
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    float: left;
    top: 0;
    margin-bottom: 20px;
    -webkit-appearance: none;
    outline: none;
    resize: none;
    color: $color-white;

    &:before {
      content: "Kies een bestand";
      position: absolute;
      width: 100%;
      height: 50px;
      float: left;
      padding: 10px;
      text-align: center;
      color: $color-white;
      background-color: $color-black;
    }
  }

  input[type="submit"] {
    margin-top: 0 !important;
  }

  @include grid-media($sm) {
    label,
    select {
      font-size: $min-font-size-p;
    }
  }
}

@include grid-media($sm) {
  .form--image {
    height: 330px !important;
  }
}

div.alert {
  display: none !important;
  margin-bottom: 20px;
  padding: 20px;
  color: $color-white;

  ul {
    list-style: none;
  }
}

div.alert-danger {
  border: 0 !important;
  background-color: $color-red !important;
}

.form-contact--wrapper {
  background-color: $color-white;

  .grid__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @include grid-media($sm) {
      width: 100%;
      padding: 40px 20px !important;
    }
  }
}

.form--contact {
  display: inline-block;
  width: 100%;
  background-color: $color-white;
}

.form-half--wrapper {
  .grid__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @include grid-media($sm) {
      width: 100%;
      padding: 40px 20px !important;
    }
  }
}

.form-background--wrapper {
  display: inline-block;
  width: 100%;
  background-color: $color-white;

  .grid__container {
    padding: 80px 40px;

    @include grid-media($sm) {
      width: 100%;
      padding: 40px 20px !important;
    }
  }
}

#form--rental {
  &:focus {
    outline: 0;
  }
}
