/* ==========================================================================
Columns two
========================================================================== */
.columnstwo {
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  width: 100%;

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }
}

@include grid-media($sm) {
  .columnstwo {
    flex-direction: column;

    .order-1 {
      order: 1;
    }

    .order-2 {
      order: 2;
    }
  }
}

/* ==========================================================================
Columns two inner
========================================================================== */
.columnstwo-inner {
  padding: 80px 40px;

  &.extra-padding {
    padding: 160px 40px;
  }
}

@include grid-media($sm) {
  .columnstwo-inner {
    padding: 40px 20px;
  }
}

/* ==========================================================================
Columns two
========================================================================== */
.columnstwo-text {
  .btn {
    text-transform: uppercase;
  }
}

/* ==========================================================================
Columns two image
========================================================================== */
.columnstwo-image {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include grid-media($sm) {
    img {
      position: relative;
    }
  }

  @include grid-media($sm) {
    .background--image-desktop {
      display: none;
    }
  }
  
  .background--image-mobile {
    display: none;
  }
  
  
  @include grid-media($sm) {
    .background--image-mobile {
      display: block;
      height: 330px;
      min-height: 330px !important;
    }
  }
}
