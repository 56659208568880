/* --- Fonts --- */
$max-font-size-h1: 48px;
$min-font-size-h1: 40px;

$max-font-size-h2: 40px;
$min-font-size-h2: 24px;

$max-font-size-h3: 32px;
$min-font-size-h3: 20px;

$max-font-size-h4: 24px;
$min-font-size-h4: 18px;

$max-font-size-h5: 18px;
$min-font-size-h5: 16px;

$max-font-size-h6: 16px;
$min-font-size-h6: 16px;

$max-font-size-p: 14px;
$min-font-size-p: 12px;

$max-font-size-large: 24px;
$min-font-size-large: 24px;

$max-font-size-small: 12px;
$min-font-size-small: 12px;

$max-font-size-nav: 16px;
$min-font-size-nav: 16px;

$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-light: 300;

$font-family-default: "Montserrat", sans-serif;
$font-family-heading: "Hermes", sans-serif;

/* --- Colors --- */

/* Generic */
$color-black: #000000;
$color-light-gray: #f5f5f5;
$color-white: #ffffff;
$color-red: #e20d2b;
$color-light-blue: #acc2d9;
$color-blue: #a5cadd;
$color-yellow: #ffc107;
$color-cream: #fffaec;

$color-text-default: $color-black;

/* Specific */
$color-body-background: $color-light-gray;

/* --- Line Height --- */
$line-height-default: 1.6;
$line-height-heading: 0.8;

/* --- Grid --- */
$max-width: 1280px;

$xs: new-breakpoint(max-width 640px);
$sm: new-breakpoint(max-width 768px);
$md: new-breakpoint(max-width 996px);
$lg: new-breakpoint(max-width $max-width);

/* --- Animations --- */
$duration: 0.3s;

/* --- Grid --- */
$gutter: 16px;
$min-width: 640px;
$max-width: 1280px;
$columns: 12;

$grid-breakpoints: (
  lg: $max-width,
  md: 1024px,
  sm: 768px,
  xs: $min-width,
);
