/* ==========================================================================
Countdown section
========================================================================== */
.countdown__section {
  position: relative;

  &::before {
    background: linear-gradient(0deg, rgba($color-white, 0.8) 0%, rgba($color-white, 0) 100%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  .grid__container {
    width: 100%;
  }

  .grid__row {
    height: 100%;
  }
}

@include grid-media($sm) {
  .countdown__section {
    .grid__container {
      position: absolute;
      height: 100%;
    }
  }
}

/* ==========================================================================
Countdown wrapper
========================================================================== */
.countdown-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  font-family: $font-family-heading;
  font-weight: bold;
  height: 100%;
  width: 100%;

  p {
    color: $color-white;
    font-family: $font-family-heading;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@include grid-media($sm) {
  .countdown-wrapper {
    min-height: 300px;
  }
}

/* ==========================================================================
Countdown background
========================================================================== */
.countdown-background {
  &::before {
    background-image: url("/packages/moeke/themes/moeke/assets/images/christmas-countdown-background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-blend-mode: multiply;
    content: "";
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

/* ==========================================================================
Countdown
========================================================================== */
.countdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 105px;
  height: 100%;
  width: 70%;
  z-index: 2;
}

/* ==========================================================================
CountdownProp
========================================================================== */
.countdownProp {
  color: $color-white;
  padding: 0 40px;
  font-size: 56px;
  height: 100%;
  width: 67px;

  &:first-of-type {
    margin-top: -31px;
    margin-left: 0px;
  }

  &:nth-of-type(2) {
    margin-top: -80px;
    margin-left: 0;
  }

  &:nth-of-type(3) {
    margin-top: 0;
    margin-left: -5px;
  }

  &:nth-of-type(4) {
    margin-top: -45px;
    margin-right: 82px;
  }
}

@media only screen and (max-width: 1199px) {
  .countdownProp {
    &:first-of-type {
      margin-left: -5px;
    }
  
    &:nth-of-type(2) {
      margin-left: 0;
    }
  
    &:nth-of-type(3) {
      margin-left: 0;
    }
  
    &:nth-of-type(4) {
      margin-top: -38px;
      margin-right: 70px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .countdownProp {
    &:first-of-type {
      margin-left: -10px;
    }
  
    &:nth-of-type(2) {
      margin-top: -73px;
      margin-left: 0;
    }

    &:nth-of-type(3) {
      margin-left: -13px;
    }
  
    &:nth-of-type(4) {
      margin-top: -38px;
      margin-right: 56px;
    }
  }
}

@media only screen and (max-width: 998px) {
  .countdownProp {
    &:first-of-type {
      margin-left: -20px;
    }
  
    &:nth-of-type(2) {
      margin-left: 7px;
    }

    &:nth-of-type(3) {
      margin-top: -10px;
      margin-left: 0;
    }
  
    &:nth-of-type(4) {
      margin-right: 25px;
    }
  }
}

@media only screen and (max-width: 912px) {
  .countdownProp {
    font-size: 36px;

    &:first-of-type {
      margin-top: -40px;
      margin-left: -11px;
    }
  
    &:nth-of-type(2) {
      margin-top: -80px;
      margin-left: 1px;
    }

    &:nth-of-type(3) {
      margin-top: -15px;
      margin-left: -6px;
    }
  
    &:nth-of-type(4) {
      margin-top: -45px;
      margin-right: 23px;
    }
  }
}

@media only screen and (max-width: 890px) {
  .countdownProp {
    &:first-of-type {
      margin-top: -43px;
      margin-left: -11px;
    }
  
    &:nth-of-type(2) {
      margin-top: -75px;
      margin-left: 0;
    }

    &:nth-of-type(3) {
      margin-top: -15px;
    }
  
    &:nth-of-type(4) {
      margin-top: -43px;
      margin-right: 20px;
    }
  }
}

@include grid-media($sm) {
  .countdownProp {
    font-size: 28px;
    height: auto;

    &:first-of-type {
      margin-top: -40px;
      margin-left: -13px;
    }
  
    &:nth-of-type(2) {
      margin-top: -72px;
      margin-left: 0;
    }

    &:nth-of-type(3) {
      margin-top: -20px;
      margin-left: -4px;
    }
  
    &:nth-of-type(4) {
      margin-top: -44px;
      margin-right: 14px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .countdownProp {
    font-size: 24px;

    &:first-of-type {
      margin-top: -45px;
      margin-left: -14px;
    }
  
    &:nth-of-type(2) {
      margin-top: -70px;
      margin-left: -6px;
    }

    &:nth-of-type(3) {
      margin-top: -22px;
      margin-left: -4px;
    }
  
    &:nth-of-type(4) {
      margin-top: -40px;
      margin-right: 13px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .countdownProp {
    padding: 0 20px;
    font-size: 20px;

    &:first-of-type {
      margin-top: -48px;
      margin-left: 0;
    }
  
    &:nth-of-type(2) {
      margin-top: -68px;
      margin-left: 5px;
    }

    &:nth-of-type(3) {
      margin-top: -32px;
      margin-left: 0;
    }
  
    &:nth-of-type(4) {
      margin-top: -48px;
      margin-right: 7px;
    }
  }
}

@media only screen and (max-width: 540px) {
  .countdownProp {
    padding: 0 30px;
    width: 40px;

    &:first-of-type {
      margin-top: -50px;
      margin-left: -12px;
    }
  
    &:nth-of-type(2) {
      margin-top: -70px;
      margin-left: -1px;
    }

    &:nth-of-type(3) {
      margin-top: -35px;
      margin-left: -3;
    }
  
    &:nth-of-type(4) {
      margin-top: -51px;
      margin-right: 14px;
    }
  }
}

@media only screen and (max-width: 410px) {
  .countdown {
    margin-top: 72px;
  }

  .countdownProp {
    font-size: 16px;
    padding: 0 20px;
    width: 40px;

    &:first-of-type {
      margin-top: -17px;
      margin-left: -6px;
    }
  
    &:nth-of-type(2) {
      margin-top: -32px;
      margin-left: 2px;
    }

    &:nth-of-type(3) {
      margin-top: -6px;
      margin-left: 0;
    }
  
    &:nth-of-type(4) {
      margin-top: -60px;
      margin-right: 11px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .countdownProp {
    &:first-of-type {
      margin-top: -15px;
      margin-left: -8px;
    }
  
    &:nth-of-type(2) {
      margin-top: -30px;
      margin-left: 2px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .countdownProp {
    font-size: 14px;

    &:first-of-type {
      margin-top: -20px;
    }
  
    &:nth-of-type(2) {
      margin-top: -36px;
      margin-left: 0;
    }

    &:nth-of-type(3) {
      margin-top: -9px;
      margin-left: -2px;
    }
  
    &:nth-of-type(4) {
      margin-top: -20px;
    }
  }
}
