.page .btn {
  font-size: $max-font-size-nav;
  font-weight: 700;
  display: inline-block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 15px;
  padding: 10px 25px;
  cursor: pointer;

  @include transition();

  @include grid-media($sm) {
    font-size: $min-font-size-nav;
  }
}

.btn--bordered {
  border: 2px solid;
  border-radius: 5px;

  &.btn--black {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &.btn--white {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-black;

    &:hover,
    &:focus {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &.btn--red {
    border-color: $color-red;
    background-color: $color-white;
    color: $color-red !important;

    &:hover,
    &:focus {
      background-color: $color-red;
      color: $color-white !important;
    }
  }
}

.btn--filled {
  border: 2px solid;

  &.btn--red {
    border-color: $color-red;
    background-color: $color-red;
    color: $color-white !important;

    &:hover,
    &:focus {
      border-color: darken($color-red, 7%);
      background-color: darken($color-red, 7%);
    }
  }

  &.btn--white {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-red !important;

    &:hover,
    &:focus {
      border-color: darken($color-white, 7%);
      background-color: darken($color-white, 7%);
    }
  }

  &.btn--blue {
    border-color: $color-blue;
    background-color: $color-blue;
    color: $color-white !important;

    &:hover,
    &:focus {
      border-color: darken($color-blue, 7%);
      background-color: darken($color-blue, 7%);
    }
  }
}

.btn--contact {
  position: fixed !important;
  left: 20px;
  bottom: 20px;
  min-width: 270px;
  background-color: darken($color-blue, 7%);
  text-align: center;
  border: 0;
  border-radius: 5px;
  z-index: 3;

  &:hover,
  &:focus {
    border-color: darken($color-blue, 12%);
    background-color: darken($color-blue, 12%);
  }
}

.btn--brochure {
  position: fixed !important;
  left: 20px;
  bottom: 80px;
  min-width: 270px;
  background-color: $color-white;
  text-align: center;
  border: 2px solid darken($color-blue, 7%);
  border-radius: 5px;
  z-index: 3;

  span {
    color: darken($color-blue, 7%) !important;
  }

  &:hover,
  &:focus {
    border-color: darken($color-blue, 12%);
    background-color: darken($color-blue, 12%);

    span {
      color: $color-white !important;
    }
  }
}

.btn--red {
  @extend
    .btn,
    .btn--filled,
    .background--red,
    .btn-min-width;

  color: $color-white !important;
  border-radius: 5px;
  margin: 5px;
}

.btn--white {
  @extend
    .btn,
    .btn--filled,
    .background--white,
    .btn-min-width;

  color: $color-red !important;
  border-radius: 5px;
  margin: 5px;
}

.btn-min-width {
  text-align: center;
  min-width: 250px;
}
