.specifications {
  &:focus {
    outline: 0;
  }

  .section__inner {
    width: 100%;
  }
}

.specifications--title {
  margin-bottom: 30px;
}

.specifications--subtitle {
  margin-top: 15px;
  margin-bottom: 15px;
}

.specifications--item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.specifications--icon {
  padding-right: 10px;

  i,
  svg {
    font-size: 22px;
  }
}

.specifications--icon-text {
  font-size: 14px;
}
