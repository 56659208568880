.header {
  position: relative;
  height: 70px;
  width: 100%;
  z-index: 100;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__list {
    list-style: none;
    margin-left: 0 !important;
  }

  &__item {
    position: relative;
    display: inline-block;
    float: left;
    font-size: $max-font-size-nav;
    line-height: 70px !important;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 30px;

      @include grid-media($md) {
        margin-right: 10px;
      }
    }

    a {
      padding: 10px 0;
      text-decoration: none;
      color: $color-white;
      font-size: 14px;
    }

    &:hover {

      > ul.header__sublist {
        display: block;
      }

      > a {
        padding-top: 3px;
        border-top: 1px solid $color-white;
      }

      > .header__item--arrow {
        color: $color-black !important;
      }

    }

    &--active {

      > a {
        padding-top: 3px;
        border-top: 1px solid $color-white;
      }

      > .header__item--arrow {
        color: $color-black !important;
      }

      > .header__sublist .header__item--active {
        background-color: $color-white;

        a {
          border-top: 0;
          color: $color-black;
        }
      }

    }

    &--arrow {
      display: none;
      position: absolute;
      top: 0;
      right: 10px;
      padding: 5px 15px;
      text-align: right;
      font-size: $min-font-size-h3;
      color: $color-white;
      cursor: pointer;
    }

  }

  &__sublist {
    display: none;
    position: absolute;
    top: 0;
    left: -15px;
    min-width: 200px;
    margin-left: 0 !important;
    padding-top: 70px;
    list-style: none;
    z-index: -1;
  }

  &__subitem {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    font-weight: 400;
    line-height: 45px !important;
    text-transform: uppercase;
    background-color: $color-red;

    a {
      width: 100%;
      float: left;
      padding: 10px 15px;
      font-family: $font-family-heading;
    }

    &:hover {
      background-color: $color-white;

      > a {
        color: $color-black;
      }
    }
  }

  &__logo {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 50px;
    padding-top: 10px;

    img {
      height: 50px;
    }
  }

  &__navicon {
    display: none;
    position: relative;
    margin-top: 20px;
    padding: 10px 0;
    user-select: none;
    cursor: pointer;

    &--bars {
      &:before, &:after {
        transition: $duration / 2;
      }
    }

    &--bars {
      position: relative;
      width: 30px;
      height: 5px;
      background-color: $color-white;
      transition: $duration / 2;

      &:before, &:after {
        display: block;
        content: "";
        height: 5px;
        width: 30px;
        background-color: $color-white;
        position: absolute;
        transition: $duration / 2;
      }

      &:before {
        top: .625rem;
      }

      &:after {
        top: -.625rem;
        height: 5px;
      }
    }

    &--open:not(.steps) .header__navicon--bars:before,
    &--open:not(.steps) .header__navicon--bars:after {
      top: 0 !important;
    }

    &--open .header__navicon--bars:before,
    &--open .header__navicon--bars:after {
      transition: $duration / 2;
      background-color: color($color-white);
    }

    &--open {

      &:hover {
        .header__navicon--bars {
          &:before,
          &:after {
            background-color: color($color-white);
          }
        }
      }

      .header__navicon--bars {
        background-color: transparent;

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  @media (min-width: 1000px) and (max-width: 1200px) {
    &__item {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &__logo {
      width: 140px;
    }
  }

  @include grid-media($md) {
    &__logo {
      width: 150px;
    }

    &__nav {
      //display: none;
      position: fixed;
      top: 0;
      right: -250px;
      width: 250px;
      height: 100%;
      padding-bottom: 90px;
      background-color: $color-red;
      overflow: auto;
    }

    &__list {
      padding-top: 60px;
    }

    &__item,
    &__subitem {
      display: block;
      width: 100%;
      margin-right: 0;
      font-size: $min-font-size-nav;
    }

    &__item {
      line-height: 45px !important;

      &--arrow {
        display: block;
      }

      a {
        width: 100%;
        float: left;
        padding: 5px 15px;
        font-size: 13px;
      }

      &:hover {

        > a {
          padding-top: 5px;
          border-top: 0;
          text-decoration: underline;
        }

        > ul.header__sublist {
          display: none;
        }

      }

      &--active {
        background: none !important;

        > a {
          padding-top: 5px;
          border-top: 0;
          text-decoration: underline;
        }
      }
    }

    &__sublist {
      position: relative;
      left: 0;
      width: 100%;
      padding-top: 0;
      z-index: 0;
    }

    &__subitem {

      &:hover {
        background: none;
      }
    }

    &__navicon {
      display: inline-block;
    }
  }
}

.arrangements--subnav {
  margin-top: 0 !important;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: $color-white;
  padding: 10px 0;

  @include grid-media($md) {
    display: none;
  }
}

.arrangements--subnav-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.arrangements--subnav-link {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  position: relative;

  &::before {
    background-color: black;
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    height: 1px;
    width: 100%;
  }

  &:hover,
  &:focus {
    &::before {
      top: -1px;
      opacity: 1;
    }
  }
}
