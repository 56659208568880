.ccm-toolbar-visible {
  .header {
    position: relative;
    margin-top: 48px;
  }

  .arrangements--subnav {
    position: relative;
  }

  .hero {
    margin-top: 0 !important;
  }
}
