.reviews--block {
  &:focus {
    outline: 0;
  }
}

.reviews--block-title {
  margin-bottom: 20px;
}

.reviews--item {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.reviews--name {
  display: inline-block;
}

.reviews--stars {
  i,
  svg {
    color: $color-yellow;
  }
}

.reviews--arrangement {
  float: right;
  font-size: 14px;
  opacity: 0.5;

  @include grid-media($sm) {
    font-size: 12px;
  }
}
