.section {
  position: relative;
  width: 100%;

  &__block-link {
    width: 100%;
    height: 100%;
    float: left;
    position: absolute;

    img {
      float: left;
    }
  }

  &__inner {
    display: inline-block;
    margin: 0 auto;
    padding: 80px 40px;
    box-sizing: border-box;

    img {
      display: block;
      margin: 0 auto;
    }

    @include grid-media($sm) {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__content {
    padding: 40px;
  }

  &__padding {
    width: 100%;
    height: 100%;
    padding: 160px 40px;
  }

  @include grid-media($sm) {
    &__block-link {
      position: relative;
    }

    &__block {
      display: flex;
      overflow: hidden;
      min-height: 400px;
    }

    &__inner {
      padding: 40px 20px;
    }

    .grid__container {
      padding: 0;
    }

    &__padding {
      padding: 40px;
    }
  }
}

.content {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}
