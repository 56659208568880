/* ==========================================================================
Information modal
========================================================================== */
.lity {
  z-index: 9999;
}

.information--modal {
  background-color: $color-red;
  border-radius: 15px;
  border: 4px solid white;
  margin: 25px;
  max-width: 500px;
  text-align: center;
  padding: 50px 20px;
  position: relative;
  box-shadow: 0px 0px 0px 9px $color-red;

  @media (min-width: 576px) {
    padding: 50px 50px;
  }

  &:after {
    content: "";
    border: 4px dotted white;
    display: block;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border-radius: 10px;
  }

  h2 {
    font-family: "Hermes", sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: $color-white;
    line-height: 0.8;
    padding-bottom: 30px;
    text-transform: uppercase;
  }

  .btn {
    background-color: $color-white;
    border: 2px solid $color-white;
    border-radius: 5px;
    color: $color-red;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-default;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 30px;
    overflow: hidden;
    padding: 10px 25px;
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
    z-index: 2;

    &:focus,
    &:hover {
      background-color: $color-red;
      border-color: $color-white;
      color: $color-white !important;
    }
  }
}
